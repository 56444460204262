import './HomeNav.css';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import useVisible from '../hooks/useVisible';
import logo from '../img/logo.svg';

function HomeNav() {
   const mainNav = useRef();

   const handleMouseMove = e => {
      const container = mainNav.current,
            scrollSpeed = .8;

      if (container) {
         const mouseX = e.clientX,
               containerRect = container.getBoundingClientRect(),
               containerCenterX = containerRect.left + containerRect.width / 2,
               distanceFromCenter = mouseX - containerCenterX;
         container.scrollLeft += distanceFromCenter * scrollSpeed;
      }
   };
   
   const visible = useVisible(15);

   return (
      <>
         <Helmet>
				<title>Sled Gym Demo &bull; React Multipurpose Template</title>
			</Helmet>

         <motion.header
            className="logo"

            initial={{ opacity: 0, y: '100%' }}
				animate={visible ? {opacity: 0, y: -25} : {opacity: 1, y: 0} }
				transition={{ duration: .3, ease: 'easeInOut' }}
         >
            <div className="top-shadow" />
            <img src={logo} alt="Sled Logo" />
         </motion.header>

         <motion.main
            className="slider"
            ref={mainNav}
            onMouseMove={handleMouseMove}

            initial={{ opacity: 0, y: '100%' }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: .6, ease: 'easeInOut' }}
         >
            <nav id="navbar">
               <ul>
                  <li>
                     <Link to='/about-us'>
                        <section>
                           <h1>About us</h1>
                           <p className="badge">where it all started</p>
                        </section>

                        <footer>
                           <FontAwesomeIcon icon={solid('dumbbell')} />
                           <p>Our Mission</p>
                        </footer>
                     </Link>
                  </li>

                  <li>
                     <Link to='/team'>
                        <section>
                           <h1>Team</h1>
                           <p className="badge">of expert coaches</p>
                        </section>

                        <footer>
                           <FontAwesomeIcon icon={solid('people-group')} />
                           <p>A strong union</p>
                        </footer>
                     </Link>
                  </li>

                  <li>
                     <Link to='/pricing'>
                        <section>
                           <h1>Pricing Plans</h1>
                           <p className="badge">choose the best offer</p>
                        </section>

                        <footer>
                           <FontAwesomeIcon icon={solid('sack-dollar')} />
                           <p>Beginner or advanced</p>
                        </footer>
                     </Link>
                  </li>

                  <li>
                     <Link to='/faqs'>
                        <section>
                           <h1>Faqs</h1>
                           <p className="badge">the answers to your doubts</p>
                        </section>

                        <footer>
                           <FontAwesomeIcon icon={solid('question')} />
                           <h3>Frequently Asked Questions</h3>
                           <p>Exhaustive answers</p>
                        </footer>
                     </Link>
                  </li>

                  <li>
                     <Link to='/subscribe'>
                        <section>
                           <h1>Subscribe Now</h1>
                           <p className="badge">no pain no gain</p>
                        </section>

                        <footer>
                           <FontAwesomeIcon icon={solid('fire')} />
                           <p>Direction next level</p>
                        </footer>
                     </Link>
                  </li>
               </ul>
            </nav>
         </motion.main>
      </>
   );
}

export default HomeNav;