import sponsor1 from '../img/sponsor/sponsor1.png';
import sponsor2 from '../img/sponsor/sponsor2.png';
import sponsor3 from '../img/sponsor/sponsor3.png';
import sponsor4 from '../img/sponsor/sponsor4.png';
import sponsor5 from '../img/sponsor/sponsor5.png';
import sponsor6 from '../img/sponsor/sponsor6.png';


export default [
   {
      src: sponsor1,
      alt: 'Sponsor Name - Sled Gym'
   },
   {
      src: sponsor2,
      alt: 'Sponsor Name - Sled Gym'
   },
   {
      src: sponsor3,
      alt: 'Sponsor Name - Sled Gym'
   },
   {
      src: sponsor4,
      alt: 'Sponsor Name - Sled Gym'
   },
   {
      src: sponsor5,
      alt: 'Sponsor Name - Sled Gym'
   },
   {
      src: sponsor6,
      alt: 'Sponsor Name - Sled Gym'
   }
];