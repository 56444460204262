import team1 from '../img/team1.jpg';
import team2 from '../img/team2.jpg';
import team3 from '../img/team3.jpg';


export default [
   {
      img: team1,
      name: 'John Smith',
      role: 'Crossfit coach'
   },
   {
      img: team2,
      name: 'Armen Stark',
      role: 'Bodybuilding & cardio coach'
   },
   {
      img: team3,
      name : 'Lorena Doe',
      role: 'Martial Arts coach'
   }
];