import './Team.css';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import coaches from '../data/coaches';
import sponsors from '../data/sponsors';
import photos from '../data/photos';
import HeaderPage from '../components/HeaderPage';
import Review from '../components/Review';
import Footer from '../components/Footer';
import bg from '../img/bg2.jpg';
import training from '../img/training.jpg';
import review2 from '../img/reviews/review2.jpg';
import review3 from '../img/reviews/review3.jpg';

function Team() {
	return (
		<>
			<Helmet>
				<title>Team &bull; Sled Gym Demo &bull; React Multipurpose Template</title>
			</Helmet>

			<HeaderPage
				bg={bg}
				pageName={'Team'}
				subtitle={'of expert coaches'}
				icon={<FontAwesomeIcon icon={solid('people-group')} className="badge-icon" />}
			/>

			<motion.main
				className="container"

				initial={{ opacity: 0, y: '100%' }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: .8, ease: 'easeInOut' }}
				exit={{ opacity: 0, y: '100%' }}
			>
				<section className="txt-center">
					<h2>Learn from the best</h2>
					<h3 className="subtitle mb-2">a strong union</h3>

					<p className="mb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.</p>

					<div className="grid gtc-x3">
						{coaches.map((coach, idx) => 
							<article className="team-vertical" key={idx}>
								<img
									src={coach.img}
									alt={coach.name+' - Sled '+coach.role}
								/>
								<h2>{coach.name}</h2>
								<h3 className="subtitle">{coach.role}</h3>
							</article>
						)}
					</div>
				</section>

				<section className="grid gtc-x3 sponsor">
					{sponsors.map((sponsor, idx) => 
						<img
							key={idx}
							src={sponsor.src}
							alt={sponsor.alt}
						/>
					)}
				</section>

				<section className="grid">
					<article>
						<h2 className="mb-2">We want you to the gym</h2>
						<p className="mb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
						<p className="mb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
						<Link to='/subscribe' className="badge">Subscribe now !</Link>
					</article>

					<img src={training} className="rounded" alt="We want you to the gym" />
				</section>

				<section className="grid">
					<Review
						img={review2}
						fullName="James Holmes"
						stars={4}
						review="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea aliquam et sie commodo sed do eiusmod tempor."
					/>
					
					<Review
						img={review3}
						fullName="Alisha Harrison"
						stars={3}
						review="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim ad minim veniam quis."
					/>
				</section>
			</motion.main>

			<motion.section
				className="photos"

				initial={{ opacity: 0, y: '100%' }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: .8, ease: 'easeInOut' }}
				exit={{ opacity: 0, y: '100%' }}
			>
				{photos.map((photo, idx) =>
					<img
						key={idx}
						src={photo.src}
						className={photo.x2 === true ? 'span2' : null}
						alt={photo.alt}
					/>
				)}
			</motion.section>

			<Footer />
		</>
	);
}

export default Team;