import './PricingPlans.css';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import HeaderPage from '../components/HeaderPage';
import Review from '../components/Review';
import Footer from '../components/Footer';
import bg from '../img/bg3.jpg';
import about from '../img/about.png';
import review1 from '../img/reviews/review1.jpg';

function PricingPlans() {
	return (
		<>
			<Helmet>
				<title>Pricing Plans &bull; Sled Gym Demo &bull; React Multipurpose Template</title>
			</Helmet>

			<HeaderPage
				bg={bg}
				pageName={'Pricing Plans'}
				subtitle={'choose the best offer'}
				icon={<FontAwesomeIcon icon={solid('sack-dollar')} className="badge-icon" />}
			/>

			<motion.main
				className="container"

				initial={{ opacity: 0, y: '100%' }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: .8, ease: 'easeInOut' }}
				exit={{ opacity: 0, y: '100%' }}
			>
				<section className="txt-center">
					<h2>There is something for everyone</h2>
					<h3 className="subtitle mb-2">beginner or advanced</h3>

					<p className="mb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.</p>

               <div className="grid gtc-x3 mb-2">
						<article className="price-card">
							<h3 className="subtitle">billed monthly</h3>
							<h2>Beginners</h2>
							<h1>$ 90</h1>
							<ul>
								<li>Fully Responsive</li>
								<li>Mobile Friendly</li>
								<li>Clean Code Structure</li>
								<li>High Performance</li>
								<li>Semantic HTML5 markup</li>
								<li>CSS3 Custom properties</li>
								<li>W3C valid HTML files</li>
								<li>React js</li>
							</ul>
							<Link to='/subscribe' className="badge">Subscribe now !</Link>
						</article>

						<article className="price-card bg-dark txt-white">
							<h3 className="subtitle">billed monthly</h3>
							<h2 className="txt-white">Basic Plan</h2>
							<h1>$ 130</h1>
							<ul>
								<li>Fully Responsive</li>
								<li>Mobile Friendly</li>
								<li>Clean Code Structure</li>
								<li>High Performance</li>
								<li>Semantic HTML5 markup</li>
								<li>CSS3 Custom properties</li>
								<li>W3C valid HTML files</li>
								<li>React js</li>
							</ul>
							<Link to='/subscribe' className="badge">Subscribe now !</Link>
						</article>

						<article className="price-card">
							<h3 className="subtitle">billed monthly</h3>
							<h2>Advanced</h2>
							<h1>$ 140</h1>
							<ul>
								<li>Fully Responsive</li>
								<li>Mobile Friendly</li>
								<li>Clean Code Structure</li>
								<li>High Performance</li>
								<li>Semantic HTML5 markup</li>
								<li>CSS3 Custom properties</li>
								<li>W3C valid HTML files</li>
								<li>React js</li>
							</ul>
							<Link to='/subscribe' className="badge">Subscribe now !</Link>
						</article>                  
               </div>
				</section>

				<section className="grid">
					<img src={about} alt="We want you to the gym" />

					<Review
						img={review1}
						fullName="Jessica Snow"
						stars={5}
						review="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea aliquam et sie commodo."
					/>
				</section>
			</motion.main>

			<Footer />
		</>
	);
}

export default PricingPlans;