import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import logo from '../img/logo.svg';
import bg from '../img/bg.jpg';

function Footer() {
   const thisYear = new Date().getFullYear();
   return (
      <motion.footer
         style={{ background: `url(${bg}) no-repeat top center`, backgroundSize: 'cover' }}

         initial={{ opacity: 0, y: '100%' }}
         animate={{ opacity: 1, y: 0 }}
         transition={{ duration: 1, ease: 'easeInOut' }}
         exit={{ opacity: 0, y: '100%' }}
      >
         
         <img src={logo} className="mb-2" height="90" alt="Sled Logo" />

         <div className="container grid gtc-x3 mb-2">
            <div className="txt-left">
               <h2 className="mb-2 txt-white">Sled Gym</h2>
               <p>Lorem ipsum dolor sit amet, consectet adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore. Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod.</p>
            </div>

            <iframe className="map rounded" title="Maps" src="https://www.google.com/maps?q=Venice+Italy&t=&z=8&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" />

            <div className="hours-time">
               <h2 className="mb-2 txt-white">Working hours</h2>
               <time>
                  <p className="subtitle">Monday - Friday</p>
                  <h3 className="txt-white">7:30 - 20:30</h3>
               </time>
               <time>
                  <p className="subtitle">Saturday</p>
                  <h3 className="txt-white">7:00 - 16:00</h3>
               </time>
               <time>
                  <p className="subtitle">Sunday</p>
                  <h3 className="txt-white">Closed</h3>
               </time>
            </div>
         </div>

         <nav className="mb-2">
            <ul className="inline">
               <li><Link to ='/'>Home</Link></li>
               <li><Link to='/about-us'>About us</Link></li>
				   <li><Link to='/team'>Team</Link></li>
               <li><Link to ='/pricing'>Pricing Plans</Link></li>
               <li><Link to ='/faqs'>Faqs</Link></li>
               <li><Link to ='/subscribe'>Subscribe Now</Link></li>
            </ul>
         </nav>

         <p>&copy; Sled {thisYear}. All Rights Reserved.</p>
         <p>Developed by <a href="https://sled.travolgi.com" target="_blank" rel="noopener noreferrer">Travolgi</a>.</p>
      </motion.footer>
   );
}

export default Footer;