// if you want to use html tags or react components within the FAQ answer instead of using simple quotes or quotes, just use react fragment as done in the first FAQ

export default [
   {
      question: 'What is Sled and how does it work',
      answer: <>
         Welcome to Sled React Multipurpose Template designed to go well with various Template, Gyms, Restaurants, Bars and much more built with ReactJs. Create and customization your website so much faster, it works too much faster loading speed and you can works too much comforfaqility. This template NOT use <strong>jQuery</strong>!
      </>
   },
   {
      question: 'How can I run Sled React template',
      answer: 'It\'s very easily, but first You\'ll need to have node and npm on your machine. So Please open your command prompt and check your node and npm Version. Then install the depentencies and after you can be run your project easily. Read the docs included in the package for more details.'
   },
   {
      question: 'How can I get the customer support',
      answer: 'After purchasing the product if you need any support you can be share with us via the our TemplateMonster author profile and we will get back to you as soon as possible, within one week from the request for support.'
   },
   {
      question: 'Can I get updates and how long will I get updates for',
      answer: 'You can get the updates whenever we update Sled. At any time you can get new updates with more features and bug fixes.'
   },
   {
      question: 'Can I change any component or page as I like',
      answer: 'Yes, You can change or use any component as you like or create a new component. Build and use the components on the pages however you like. By the way you can build your website which you are choose without limitation.'
   },
   {
      question: 'Can I build a complete project or my website with this template?',
      answer: 'Yes, Why not! It was designed for this. You can build your project and complete the website as you like.'
   },
]