import { AnimatePresence } from 'framer-motion';
import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import HomeNav from './pages/HomeNav';
import AboutUs from './pages/AboutUs';
import PricingPlans from './pages/PricingPlans';
import Team from './pages/Team';
import Faqs from './pages/Faqs';
import Subscribe from './pages/Subscribe';
import ErrorPage from './pages/ErrorPage';
import ScrollToTop from './components/ScrollToTop';
import BuyNowBtn from './components/BuyNowBtn';


export default function App() {
	const location = useLocation();
	useEffect(() => {
		setTimeout(() => window.scrollTo(0, localStorage.getItem('position')), 0);
	}, [location])

	return (
		<AnimatePresence>
			<Routes location={location} key={location.key}>
				<Route path='/' element={<HomeNav />} />
				<Route path='about-us' element={<AboutUs />} />
				<Route path='team' element={<Team />} />
				<Route path='pricing' element={<PricingPlans />} />
				<Route path='faqs' element={<Faqs />} />
				<Route path='subscribe' element={<Subscribe />} />
				<Route path='/*' element={<ErrorPage />} />
			</Routes>

			<ScrollToTop />
			<BuyNowBtn />
		</AnimatePresence>
	);
}