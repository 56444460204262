import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import HeaderPage from '../components/HeaderPage';
import Footer from '../components/Footer';
import bg from '../img/bg.jpg';
import child from '../img/child.jpg';
import about from '../img/about.png';

function AboutUs() {
	return (
		<>
			<Helmet>
				<title>About Us &bull; Sled Gym Demo &bull; React Multipurpose Template</title>
			</Helmet>

			<HeaderPage
				bg={bg}
				pageName={'About us'}
				subtitle={'where it all started'}
				icon={<FontAwesomeIcon icon={solid('dumbbell')} className="badge-icon" />}
			/>

			<motion.main
				className="container"

				initial={{ opacity: 0, y: '100%' }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: .8, ease: 'easeInOut' }}
				exit={{ opacity: 0, y: '100%' }}
			>
				<section className="mb-2">
					<h2 className="txt-center">Sled Gym</h2>
					<h3 className="subtitle txt-center mb-2">Our Mission</h3>

					<div className="grid">
						<article>
							<h2 className="mb-2">Break the routine</h2>
							<p className="mb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
							<a href="https://www.templatemonster.com/website-templates/sled-react-website-template-for-gym-restaurant-bar-and-multipurpose-template-252058.html?utm_campaign=sledreact_landing_travolgi&utm_source=travolgi&utm_medium=referral&aff=travolgi" className="badge" target="_blank" rel="noopener noreferrer">Read more</a>
						</article>

						<img src={child} className="rounded" alt="Child in the gym" />
					</div>
				</section>

				<section className="grid gtc-x3 txt-center txt-white bg-dark rounded mb-2">
					<article>
						<i className="badge-icon mb-2">
							<FontAwesomeIcon icon={solid('list-check')} />
						</i>
						<h2 className="txt-white">PROGRESSION</h2>
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
					</article>

					<article>
						<i className="badge-icon mb-2">
							<FontAwesomeIcon icon={solid('heart-pulse')} />
						</i>
						<h2 className="txt-white">WORKOUT</h2>
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
					</article>

					<article>
						<i className="badge-icon mb-2">
							<FontAwesomeIcon icon={solid('plate-wheat')} />
						</i>
						<h2 className="txt-white">NUTRITION</h2>
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
					</article>
				</section>

				<section>
					<div className="grid">
						<img src={about} alt="We want you to the gym" />

						<article>
							<h2 className="mb-2">Break the routine</h2>
							<p className="mb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
							<Link to='/subscribe' className="badge">Subscribe now !</Link>
						</article>
					</div>
				</section>
			</motion.main>

			<Footer />
		</>
	);
}

export default AboutUs;