import './Subscribe.css';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, brands } from '@fortawesome/fontawesome-svg-core/import.macro';
import HeaderPage from '../components/HeaderPage';
import ContactForm from '../components/ContactForm';
import Review from '../components/Review';
import Footer from '../components/Footer';
import bg from '../img/bg4.jpg';
import review4 from '../img/reviews/review4.jpg';
import review5 from '../img/reviews/review5.jpg';

function Subscribe() {
   return (
      <>
         <Helmet>
				<title>Subscribe Now &bull; Sled Gym Demo &bull; React Multipurpose Template</title>
			</Helmet>

			<HeaderPage
				bg={bg}
				pageName={'Subscribe Now'}
				subtitle={'no pain no gain'}
				icon={<FontAwesomeIcon icon={solid('fire')} className="badge-icon" />}
			/>

			<motion.main
            className="container"

				initial={{ opacity: 0, y: '100%' }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: .8, ease: 'easeInOut' }}
				exit={{ opacity: 0, y: '100%' }}
         >
				<section>
					<h2 className="txt-center">Build the new you</h2>
					<h3 className="subtitle txt-center mb-2">direction next level</h3>

					<p className="mb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>

               <div className="grid gtc-2-1 mb-2">
                  <ContactForm />

                  <ul className="contact-info">
                     <li>
                        <FontAwesomeIcon icon={solid('location-dot')} />
                        <a href="https://www.templatemonster.com/website-templates/sled-react-website-template-for-gym-restaurant-bar-and-multipurpose-template-252058.html?utm_campaign=sledreact_landing_travolgi&utm_source=travolgi&utm_medium=referral&aff=travolgi" target="_blank" rel="noopener noreferrer">
                           <address>Your Address 1, Zip - City</address>
                        </a>
                     </li>
                     <li>
                        <FontAwesomeIcon icon={solid('phone')} />
                        <a href="tel:">+39 321 123456</a>
                     </li>             
                     <li>
                        <FontAwesomeIcon icon={solid('envelope')} />
                        <a href="mailto:">youremail@address.com</a>
                     </li>
                     <li className="txt-center">
                        <a href="https://www.templatemonster.com/website-templates/sled-react-website-template-for-gym-restaurant-bar-and-multipurpose-template-252058.html?utm_campaign=sledreact_landing_travolgi&utm_source=travolgi&utm_medium=referral&aff=travolgi" target="_blank" rel="noopener noreferrer">
                           <FontAwesomeIcon icon={brands('facebook-f')} />
                        </a>
                        <a href="https://www.templatemonster.com/website-templates/sled-react-website-template-for-gym-restaurant-bar-and-multipurpose-template-252058.html?utm_campaign=sledreact_landing_travolgi&utm_source=travolgi&utm_medium=referral&aff=travolgi" target="_blank" rel="noopener noreferrer">
                           <FontAwesomeIcon icon={brands('instagram')} />
                        </a>
                        <a href="https://www.templatemonster.com/website-templates/sled-react-website-template-for-gym-restaurant-bar-and-multipurpose-template-252058.html?utm_campaign=sledreact_landing_travolgi&utm_source=travolgi&utm_medium=referral&aff=travolgi" target="_blank" rel="noopener noreferrer">
                           <FontAwesomeIcon icon={brands('twitter')} />
                        </a>
                     </li>
                  </ul>
               </div>
            </section>

            <section>
               <iframe className="map rounded" title="Maps" src="https://www.google.com/maps?q=Venice+Italy&t=&z=8&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" />
            </section>

            <section className="grid">
					<Review
						img={review4}
						fullName="Aria Lower"
						stars={4}
						review="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea aliquam et sie commodo sed do eiusmod tempor."
					/>
					
					<Review
						img={review5}
						fullName="Leonardo Solem"
						stars={3}
						review="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim ad minim veniam quis."
					/>
				</section>
			</motion.main>

			<Footer />
      </>
   );
}

export default Subscribe;