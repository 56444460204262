import './Faq.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import useIsOpen from '../hooks/useIsOpen';


function Faq({ question, answer }) {
	const [isOpen, handleToggle] = useIsOpen();

	return (
		<article className="faq" data-faq-opened={isOpen} onClick={handleToggle}>
			<div className="faq-question">
				<h3>{question}?</h3>

				{isOpen ?
					<FontAwesomeIcon icon={solid('close')}>
						<span className="sr-only">Close Faq</span>
					</FontAwesomeIcon>
					:
					<FontAwesomeIcon icon={solid('plus')}>
						<span className="sr-only">Open Faq</span>
					</FontAwesomeIcon>
				}
			</div>

			<div className="faq-answer">
				<p>{answer}</p>
			</div>
		</article>
	);
}

export default Faq;