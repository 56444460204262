import photo1 from '../img/photo1.jpg';
import photo2 from '../img/photo2.jpg';
import photo3 from '../img/photo3.jpg';
import photo4 from '../img/photo4.jpg';
import photo5 from '../img/photo5.jpg';
import photo6 from '../img/photo6.jpg';


export default [
   {
      src: photo1,
      x2: true,
      alt: 'Girl doing push-ups'
   },
   {
      src: photo2,
      alt: 'Weight training'
   },
   {
      src: photo3,
      alt: 'Bag boxing workout'
   },
   {
      src: photo4,
      alt: 'Bodybuilder with weights'
   },
   {
      src: photo5,
      x2: true,
      alt: 'Warrior child in the ring'
   },
   {
      src: photo6,
      alt: 'Girl doing situps'
   }
];